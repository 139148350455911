
// Write your custom style. Feel free to split your code to several files
body {
  //color: blue;
}
@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width11: 70vw; } }
.nav-navbar .nav-link {
  font-sizeq: 1.2em;
}


@media (min-width: 1200px) and (max-width: 2580px){
  .auction-item-list-img {
    overflow: hidden;
    height: 550px;
    position: relative;
    width: 630px;
    overflow: hidden;
    transition: width 2s;
    padding: 30px;
  }
}